import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Pagination } from "../models/pagination.model";
import { environment } from "../../../environments/environment";
import {
    DashboardStats,
    KmsWalletDto,
    LatestAmlReport,
    MerchantDto,
    MerchantFilter,
    Operation,
    ReferralStats,
    Rental,
    RentalStatsGraph,
    ReportData,
    ReportEntity,
    ReportStats,
    StopWalletRequest,
    StopWalletResponse,
    StopWalletTransaction,
    SubscriptionStats,
    Transaction,
    TransactionStats,
    UserEntity
} from "../models/fraud.models";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class FraudService {
    private readonly _apiUrl: string = environment.apiUrl;
    private readonly _httpClient: HttpClient = inject(HttpClient);

    searchTransactionsForAdmin(body: { userUuid?: string } = {}): Observable<Pagination<Transaction[]>> {
        return this._httpClient.post<Pagination<Transaction[]>>(`${ this._apiUrl }api/admin/aml-scans/search`, body).pipe(
            map((response) => {
                return {
                    ...response,
                    content: response.content.map((transaction) => {
                        return {
                            ...transaction,
                            latestAmlReport: JSON.parse(transaction.latestAmlReport)
                        }
                    })
                }
            })
        )
    }

    searchReportsForAdmin(): Observable<Pagination<ReportEntity[]>> {
        return this._httpClient.post<Pagination<ReportEntity[]>>(`${ this._apiUrl }api/admin/reports/search`, {});
    }

    searchUsers(body?: MerchantFilter): Observable<MerchantDto[]> {
        return this._httpClient.post<MerchantDto[]>(`${ this._apiUrl }api/admin/users/search`, (body ? body : {}));
    }

    getUserByUuid(uuid: string): Observable<UserEntity> {
        return this._httpClient.post<UserEntity>(`${ this._apiUrl }api/admin/users/search/${ uuid }`, {});
    }

    putUserByUuid(uuid: string, body: { createReferralLinks: boolean }): Observable<any> {
        return this._httpClient.put<any>(`${ this._apiUrl }api/admin/users/search/${ uuid }`, body);
    }

    getDashboards(): Observable<DashboardStats> {
        return this._httpClient.post<DashboardStats>(`${ this._apiUrl }api/admin/dashboard/stats`, {});
    }

    getReports(uuid: string): Observable<Pagination<Omit<ReportEntity, 'data'>[]>> {
        const body = {
            userUuid: uuid,
        };
        return this._httpClient.post<Pagination<Omit<ReportEntity, 'data'>[]>>(`${ this._apiUrl }api/admin/reports/search`, body);
    }

    getReport(id: number): Observable<ReportEntity<ReportData> | undefined> {
        let params = new HttpParams();
        params = params.append('id', id.toString());
        return this._httpClient.post<ReportEntity<string>>(`${ this._apiUrl }api/admin/reports`, {}, { params })
            .pipe(map((report) => {
                return {
                    ...report,
                    data: JSON.parse(report.data ?? '')
                }
            }));
    }

    getTransactionUuidUser(uuid: string): Observable<Pagination<Transaction<LatestAmlReport>[]>> {
        const body = {
            userUuid: uuid,
            status: "COMPLETE"
        };
        return this._httpClient.post<Pagination<Transaction<string>[]>>(`${ this._apiUrl }api/admin/aml-scans/search`, body).pipe(
            map((response) => {
                return {
                    ...response,
                    content: response.content.map((transaction) => {
                        return {
                            ...transaction,
                            latestAmlReport: JSON.parse(transaction.latestAmlReport)
                        }
                    })
                }
            })
        );
    }

    getRentals(uuid: string): Observable<Pagination<Rental[]>> {
        const body = {
            userUuid: uuid,
            status: "COMPLETE"
        };
        return this._httpClient.post<Pagination<Rental[]>>(`${ this._apiUrl }api/admin/rentals/search`, body);
    }

    getOperations(uuid: string): Observable<Pagination<Operation[]>> {
        const body = {
            userUuid: uuid
        };
        return this._httpClient.post<Pagination<Operation[]>>(`${ this._apiUrl }api/admin/operations/search`, body);
    }

    getReportsStats(uuid: string): Observable<ReportStats> {
        let params = new HttpParams();
        params = params.append('userUuid', uuid);
        return this._httpClient.get<ReportStats>(`${ this._apiUrl }api/admin/reports/stats`, { params });
    }

    getTransactionsStats(uuid?: string): Observable<TransactionStats> {
        let params = new HttpParams();
        uuid && (params = params.append('userId', uuid));
        return this._httpClient.get<TransactionStats>(`${ this._apiUrl }api/admin/aml-scans/stats`, { params });
    }

    getReferralsStats(uuid?: string): Observable<ReferralStats> {
        let params = new HttpParams();
        uuid && (params = params.append('userUuid', uuid));
        return this._httpClient.get<ReferralStats>(`${ this._apiUrl }api/admin/referrals/stats`, { params });
    }

    getSubscriptionStats(uuid?: string): Observable<SubscriptionStats> {
        let params = new HttpParams();
        uuid && (params = params.append('userUuid', uuid));
        return this._httpClient.get<SubscriptionStats>(`${ this._apiUrl }api/admin/subscription/stats`, { params });
    }

    updateReportToSentStatus(id: number, body: { status: string }): Observable<never> {
        return this._httpClient.post<never>(`${ this._apiUrl }api/admin/reports/send/${ id }`, body);
    }

    updateReportToCancelStatus(id: number): Observable<never> {
        return this._httpClient.post<never>(`${ this._apiUrl }api/admin/reports/cancel/${ id }`, {});
    }

    getDailyGraphDashboard(from: string, to: string): Observable<RentalStatsGraph[]> {
        let params = new HttpParams();
        params = params.append('from', from);
        params = params.append('to', to);
        return this._httpClient.get<RentalStatsGraph[]>(`${ this._apiUrl }api/admin/rentals/dashboard/daily-graph`, { params });
    }

    getMonthDashboard(from: string, to: string): Observable<ReferralStats[]> {
        let params = new HttpParams();
        params = params.append('from', from);
        params = params.append('to', to);
        return this._httpClient.get<ReferralStats[]>(`${ this._apiUrl }api/admin/rentals/dashboard/month`, { params });
    }

    getStopWalletResponse(queryParams?: Partial<{
        search: string;
        page: number,
        size: number
    }>): Observable<Pagination<StopWalletResponse[]>> {
        let params = new HttpParams({ fromObject: queryParams ?? {} });
        return this._httpClient.get<Pagination<StopWalletResponse[]>>(`${ this._apiUrl }api/manager/stop-wallets`, { params });
    }

    editStopWallet(stopWalletId: number, body: Partial<Omit<StopWalletResponse, 'id'>>): Observable<StopWalletResponse> {
        return this._httpClient.patch<StopWalletResponse>(`${ this._apiUrl }api/manager/stop-wallets/${ stopWalletId }`, body);
    }

    createStopWalletEntity(body: StopWalletRequest[]): Observable<StopWalletResponse[]> {
        return this._httpClient.post<StopWalletResponse[]>(`${ this._apiUrl }api/manager/stop-wallets`, body);
    }

    postFraudEmailsTemplate(): Observable<any> {
        return this._httpClient.post(`${ this._apiUrl }api/manager/fraud-emails/template`, {});
    }

    postFraudEmails(): Observable<any> {
        return this._httpClient.post(`${ this._apiUrl }api/manager/fraud-emails`, {});
    }

    getStopWalletById(id: number): Observable<StopWalletResponse> {
        return this._httpClient.get<StopWalletResponse>(`${ this._apiUrl }api/manager/stop-wallets/${ id }`);
    }

    getStopWalletTransactionById(id: number, queryParams?: Partial<{
        search: string;
        page: number,
        size: number
    }>): Observable<Pagination<StopWalletTransaction[]>> {
        let params = new HttpParams({ fromObject: queryParams ?? {} });
        return this._httpClient.get<Pagination<StopWalletTransaction[]>>(`${ this._apiUrl }api/manager/${ id }/stop-wallet-transactions`, { params });
    }

    getManagerRiskTypes(): Observable<any> {
        return this._httpClient.get<any>(`${ this._apiUrl }api/manager/blocking-tx/risk-types`);
    }

    postBlockingCalcluateAmount(body: { amount: number, network: string, riskType: string }): Observable<{
        amount: number,
        nativeAmount: number
    }> {
        return this._httpClient.post<{
            amount: number,
            nativeAmount: number
        }>(`${ this._apiUrl }api/manager/blocking-tx/calculate-amount`, body);
    }

    putWalletType(walletType: string, walletId: number, transactionUuid: string): Observable<any> {
        return this._httpClient.put<any>(`${ this._apiUrl }api/manager/${ walletId }/stop-wallet-transactions/${ transactionUuid }`, { walletType });
    }

    getKmsWallets(network: string): Observable<KmsWalletDto[]> {
        let params = new HttpParams();
        // params = params.append('isMainnet', 'false');
        params = params.append('network', network);
        return this._httpClient.get<KmsWalletDto[]>(`${ this._apiUrl }api/manager/kms-wallets`, { params });
    }

    postSendBlockingTx(body: { amounts: number[], kmsWalletId: number }, transactionUuid: string): Observable<any> {
        return this._httpClient.post<any>(`${ this._apiUrl }api/manager/stop-wallet-transaction/${ transactionUuid }/send-blocking-tx`, body);
    }
}
